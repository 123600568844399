var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-auto",class:[
    { 'lg:mb-8': !_vm.isLast },
    { 'bg-white text-center md:py-12 py-12': !_vm.isVerticalRender },
    { 'bg-gray-light rounded-lg py-4': _vm.isVerticalRender },
  ]},[_c('container',{staticClass:"max-w-screen-lg"},[(_vm.isVerticalRender)?_c('h2',{staticClass:"text-xl leading-4 font-bold mb-5"},[_vm._v("\n      "+_vm._s(_vm.$t('organisms.usps_block.text'))+"\n    ")]):_c('ui-title',{staticClass:"mb-10",attrs:{"type":"h2"}},[_vm._v("\n      "+_vm._s(_vm.$t('organisms.usps_block.text'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"inline-flex flex-col",class:[
        _vm.isVerticalRender
          ? 'flex-col'
          : 'mb-8 md:flex md:flex-row md:justify-between',
      ]},[_c('usp-with-icon',{attrs:{"icon":"fast-delivery-neo","text":_vm.$t('organisms.usps_block.usp-1-text'),"is-vertical-render":_vm.isVerticalRender}}),_vm._v(" "),_c('usp-with-icon',{attrs:{"icon":"trusted-globe","text":_vm.$t('organisms.usps_block.usp-2-text'),"is-vertical-render":_vm.isVerticalRender}}),_vm._v(" "),_c('usp-with-icon',{attrs:{"icon":"square-lock","text":_vm.$t('organisms.usps_block.usp-3-text'),"is-vertical-render":_vm.isVerticalRender}})],1),_vm._v(" "),(_vm.enableCta)?_c('div',{staticClass:"w-full"},[_c('ui-button',{staticClass:"w-full max-w-80",attrs:{"blank":"","url":_vm.url}},[_vm._v("\n        "+_vm._s(_vm.$t('organisms.usps_block.button-text'))+"\n      ")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }