
import { mapGetters } from 'vuex'
import UspWithIcon from './usp-with-icon.vue'
import { Container } from '~/components/grid'
import { UiTitle, UiButton } from '~/components/atoms'
import { ABOUT_US_URL } from '~/utils/constants/pages'

export default {
  components: {
    Container,
    UiTitle,
    UiButton,
    UspWithIcon,
  },
  props: {
    enableCta: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    isVerticalRender: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('context', ['isGlobalMarketplace']),
    url() {
      return this.isGlobalMarketplace
        ? `${ABOUT_US_URL}?utm_source=recharge&utm_medium=usp_section`
        : this.$t('organisms.usps_block.button-link')
    },
  },
}
