<template>
  <div
    class="flex flex-1 mb-4 text-start self-start first:px-0 last:px-0"
    :class="{
      'items-center md:flex-col md:mx-auto md:px-4': !isVerticalRender,
    }"
  >
    <div
      class="me-4 mb-0"
      :class="[
        { 'w-8 h-8 md:w-16 md:h-16 md:me-0 md:mb-4': !isVerticalRender },
        { 'w-12': isVerticalRender },
      ]"
    >
      <icon
        :icon="icon"
        width="full"
        height="auto"
        :image-dimension="[20, 20]"
      />
    </div>
    <rich-text-renderer
      v-if="text"
      :document="text"
      :class-names="textRendererClasses"
    />
  </div>
</template>

<script>
import VueTypes from 'vue-types'
import RichTextRenderer from '~/components/renderers/rich-text-renderer'
import Icon from '~/components/atoms/icon'

export default {
  components: {
    RichTextRenderer,
    Icon,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    text: VueTypes.any,
    isVerticalRender: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    textRendererClasses() {
      return this.isVerticalRender ? '' : 'md:text-center'
    },
  },
}
</script>
